<template>
    <loader v-bind="{ loading }">
        <columns>
            <column class="is-flex is-justify-content-end">
                <div class="buttons has-addons is-rounded">
                    <action-button @click="pause" class="is-small is-rounded" v-if="template.status === 'active'">Pause Schedule</action-button>
                    <action-button @click="resume" class="is-small is-rounded" v-if="template.status === 'paused'">Resume Schedule</action-button>
                    <action-button @click="deleteTemplate" class="is-small is-rounded is-danger">Delete</action-button>
                </div>
            </column>
        </columns>
        <form>
            <columns>
                <column>
                    <text-input classes="is-medium is-rounded" required :value="template.name" @input="updateName"
                        :error="$root.errors.name">
                        Name
                    </text-input>
                </column>
                <column>
                    <data-selector :disabled="template.schedules_count > 0" classes="is-medium is-rounded" required searchable :items="customer_list"
                        :value="template.customer_id" @input="updateCustomer" :error="$root.errors.customer_id"
                        value-key="id" label-key="name">
                        Customer
                    </data-selector>
                </column>
                <column>
                    <data-selector :disabled="template.schedules_count > 0" classes="is-medium is-rounded" :error="$root.errors.location_id"
                        :items="customerLocations" :value="template.location_id" @input="updateLocation" value-key="id"
                        label-key="name" required>
                        Location
                    </data-selector>
                </column>
                <column>
                    <data-selector classes="is-medium is-rounded" :error="$root.errors.team_id" :items="availableTeams"
                        :value="template.team_id" @input="updateTeam" value-key="id" label-key="name" required>
                        Team
                    </data-selector>
                </column>
            </columns>
            <columns>
                <column class="is-6">
                    <text-input classes="is-medium is-rounded" required :error="$root.errors.reference"
                        :value="template.reference" @input="updateReference">
                        Reference
                    </text-input>
                </column>
                <column>
                    <data-selector classes="is-medium is-rounded" :error="$root.errors.classification_id"
                        :items="work_order_classifications" :value="template.classification_id" @input="updateClassification"
                        value-key="id" label-key="title" required>
                        Classification
                    </data-selector>
                </column>
                <column>
                    <data-selector classes="is-medium is-rounded" :error="$root.errors.priority_id" :items="priorities"
                        :value="template.priority_id" @input="updatePriority" value-key="id" label-key="level" required>
                        Priority
                    </data-selector>
                </column>
            </columns>
            <columns>
                <column>
                    <text-area classes="is-rounded" :value="template.brief" @input="updateBrief"
                        placeholder="Any information that the team may require to complete the work ahead.">
                        Briefing
                    </text-area>
                </column>
            </columns>
            <columns>
                <column>
                    <columns>
                        <column>
                            <h4 class="title is-4">Scheduling <span class="has-text-weight-light">(Optional)</span></h4>
                            <div class="message is-warning">
                                <div class="message-body">
                                    <p>You may optionally set a <span class="has-text-weight-bold">repeat
                                            interval</span> for this template. <span class="has-text-weight-bold">Work
                                            Orders will be automatically opened</span> based on
                                        the schedule you define below.
                                    </p>
                                </div>
                            </div>
                        </column>
                    </columns>
                    <columns>
                        <column class="is-narrow">
                            <select-input 
                                classes="is-rounded" 
                                :items="intervals" 
                                :value="template.schedule.interval"
                                description="Set the repeat schedule for this template."
                                @input="updateInterval">
                                Interval
                            </select-input>
                        </column>
                        <column class="is-narrow">
                            <date-picker 
                                description="The date at which the first work order will be created."
                                classes="is-rounded" :value="template.first_run" @input="updateFirstRun">
                                First Run
                            </date-picker>
                        </column>
                        <column class="is-narrow">
                            <data-selector
                                classes="is-rounded"
                                :value="template.opening_cutoff_time"
                                @input="updateOpeningCutoffTime"
                                :items="times"
                                description="Should the previous Work Order not have uploaded and synced by this time the Work Order will be marked as skipped.">
                                Opening Cutoff Time
                            </data-selector>
                        </column>
                    </columns>
                    <columns>
                        <column>
                            <select-input :items="weekends" classes="is-rounded" :value="template.schedule.weekends"
                                @input="updateWeekends">
                                Weekends
                            </select-input>
                        </column>
                        <column>
                            <select-input classes="is-rounded" :items="holidays" :value="template.schedule.holidays"
                                @input="updateHolidays">
                                Holidays
                            </select-input>
                        </column>
                    </columns>
                </column>
            </columns>
            <columns>
                <column>
                    <submit-button class="is-medium is-rounded" :working="saving" @submit="updateTemplate">
                        Save
                    </submit-button>
                </column>
            </columns>
        </form>
    </loader>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import range from 'lodash/range'
import { priority } from '@/api'
import { resumeWorkOrderSchedule } from '@/modals'

export default {

    data: () => ({
        loading: true,
        saving: false,
        priorities: [],
        intervals: [
            { value: 'daily', label: 'Daily' },
            { value: 'weekly', label: 'Weekly' },
            { value: 'every_2_weeks', label: 'Every 2 Weeks' },
            { value: 'monthly', label: 'Monthly' },
            { value: 'every_2_months', label: 'Every 2 Months' },
            { value: 'every_3_months', label: 'Every 3 Months' },
            { value: 'every_6_months', label: 'Every 6 Months' },
            { value: 'annually', label: 'Annually' },
            { value: 'every_2_years', label: 'Every 2 Years' },
            { value: 'every_3_years', label: 'Every 3 Years' },
        ],
        days_of_the_week: [
            { value: 1, label: 'Monday' },
            { value: 2, label: 'Tuesday' },
            { value: 3, label: 'Wednesday' },
            { value: 4, label: 'Thursday' },
            { value: 5, label: 'Friday' },
            { value: 6, label: 'Saturday' },
            { value: 7, label: 'Sunday' },
        ],
        weekends: [
            { value: 'open_on_weekend', label: 'Proceed normally (open work order)' },
            { value: 'business_day_before', label: 'Open on the first prior business day' },
            { value: 'business_day_after', label: 'Open on the next business day' },
        ],
        holidays: [
            { value: 'open_on_holiday', label: 'Proceed normally (open work order)' },
            { value: 'business_day_before', label: 'Open on the first prior business day' },
            { value: 'business_day_after', label: 'Open on the next business day' },
        ],
        times: [
            { value: '06:00', label: '06:00' },
            { value: '07:00', label: '07:00' },
            { value: '08:00', label: '08:00' },
            { value: '09:00', label: '09:00' },
            { value: '10:00', label: '10:00' },
            { value: '11:00', label: '11:00' },
            { value: '12:00', label: '12:00' },
            { value: '13:00', label: '13:00' },
            { value: '14:00', label: '14:00' },
            { value: '15:00', label: '15:00' },
        ]
    }),

    async created() {
        await priority.loadPriorityList(({ data }) => this.priorities = data)
        await this.loadCustomerList()
        await this.loadTeamList()
        await this.loadWorkOrderClassificationList()
        this.loading = false
    },

    methods: {
        ...mapActions('customer', ['loadCustomerList']),
        ...mapActions('team', ['loadTeamList']),
        ...mapActions('workOrderClassification', ['loadWorkOrderClassificationList']),
        ...mapMutations('workOrderTemplate', [
            'updateCustomer',
            'updateLocation',
            'updateReference',
            'updatePriority',
            'updateTeam',
            'updateClassification',
            'updateStartingDate',
            'updateEndDate',
            'updateBrief',
            'updateInterval',
            'updateDayOfWeek',
            'updateDayOfMonth',
            'updateWeekends',
            'updateHolidays',
            'updateFirstRun',
            'updateName',
            'updateOpeningCutoffTime'
        ]),
        updateTemplate() {
            this.saving = true
            this.$store.dispatch('workOrderTemplate/save').then(() => {
                this.saving = false
                this.$toast.success('Saved')
            }, () => {
                this.saving = false
            })
        },
        pause() {
            this.pausing = true
            this.$store.dispatch('workOrderTemplate/pause').then(() => {
                this.pausing = false
                this.$toast.success('Paused')
            }, () => {
                this.pausing = false
            })
        },
        resume() {
            resumeWorkOrderSchedule(this.template.id).then((outcome) => {
                if(outcome) {
                    this.$toast.success('Resumed')
                }
            })
        },
        async deleteTemplate() {
            if(await this.$confirm({
                title: 'Delete Template',
                message: 'Are you sure you want to delete this template?'
            })) {
                this.$store.dispatch('workOrderTemplate/deleteTemplate').then(() => {
                    this.$store.commit('workOrderTemplate/clearWorkOrder')
                    this.$toast.success('Deleted')
                    this.$router.push({
                        name: 'work-order-templates'
                    })
                }, () => {})
            }
        }
    },

    computed: {
        ...mapGetters('workOrderTemplate', ['template']),
        ...mapGetters('customer', ['customer_list']),
        ...mapGetters('team', ['team_list']),
        ...mapGetters('workOrderClassification', ['work_order_classifications']),
        activeCustomer() {
            return find(this.customer_list, customer => customer.id === this.template.customer_id)
        },
        customerLocations() {
            return this.activeCustomer ? this.activeCustomer.locations : []
        },
        availableTeams() {
            return this.activeCustomer ? this.team_list.filter(team => {
                const branch_ids = flatMap(this.activeCustomer.locations.map(location => {
                    return location.branches.map(branch => branch.id)
                }))
                return branch_ids.includes(team.branch_id)
            }) : []
        },
        daysOfTheMonth() {
            return range(1, 29).map(day => ({ value: day, label: day }))
        },
        shouldDisplayDayOfMonth() {
            return ['monthly', 'every_2_months', 'every_6_months'].includes(this.template.schedule.interval)
        }
    }

}
</script>